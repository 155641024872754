var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.letter+'-'+_vm.selected,class:{
        'h4': true,
        'letter': true,
        'cursor-pointer': true,
        'hinted': _vm.hinted,
        'selected': (_vm.selected || _vm.in_solved),
        'in-spangram': _vm.in_spangram,
        'found': _vm.found
    },on:{"click":_vm.click}},[_vm._v(" "+_vm._s(_vm.letter)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }