<template>
    <svg
        :class="{
            'line': true,
            'in-spangram': in_spangram,
            'correct': correct
        }"
    >
      
        <line
            :x1="(y1*71)+27"
            :y1="(x1*65)+20"
            :x2="(y2*71)+27"
            :y2="(x2*65)+20"
            stroke-width="10px"
        />
    </svg>
</template>

<script>
export default {
    props: [ "x1", "y1", "x2", "y2" ],
    data() {
        return {
            in_spangram: false,
            correct: false
        }
    }
}
</script>