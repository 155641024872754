<template>
	<div class="container user-select-none" style="width: 450px !important;">
		<!-- Today's Theme -->
		<b-row class="w-100">
			<b-card class="theme w-100" id="theme-card" no-footer>
				<template #header>
					<p>THEME</p>
				</template>
				<b-card-text>{{ clue }}</b-card-text>
			</b-card>
		</b-row>

		<!-- Current attempt -->
		<b-row class="mt-3 w-100 attempted_word" :key="attempted_word">
			<h5 class="font-weight-bold">{{ attempted_word }}</h5>
		</b-row>

		<!-- Grid -->
		<b-row class="mt-2 w-100 position-relative">
			<letter-grid
				ref="letter-grid"
				:board="board"
				:clue="clue"
				:spangram="spangram"
				:strands="strands"
				:strand_coordinates="strand_coordinates"
				:words="words"
				@attempted-word="(w) => attempted_word = w"
				@word-found="words_found++"
				@solved="solved_count++"
			/>
		</b-row>
		
		<b-row class="mt-3 w-100">
			<div class="col-5">
				<div
					:class="{
						'btn': true,
						'btn-outline-secondary': true,
						'rounded-pill': true,
						'px-3': true,
						'py-2': true,
						'disabled': hint_disabled,
						'border': true,
						'border-2': true,
						'w-100': true
					}"
					@click="$refs['letter-grid'].mark_hinted()"
				>
				Hint</div>
			</div>
			<div clss="col-2">
				<b-col class="m-auto counter col w-100 h-100 d-flex justify-content-center align-items-center">
					<b>{{ solved_count }}</b> / <b>{{ Object.keys(strand_coordinates).length }}</b> 
				</b-col>
			</div>
			<div class="col-5">
				<div
					:class="{
					'btn': true,
					'btn-outline-dark': true,
					'rounded-pill': true,
					'px-3': true,
					'py-2': true,
					'border': true,
					'border-2': true,
					'w-100': true
					}"
					@click="startNewGame"
				>
				New Game </div>
			</div>
		</b-row>
		
	</div>
</template>

<script>
import LetterGrid from './LetterGrid.vue'

export default {
	components: {
		LetterGrid
	},
	methods: {
		startNewGame() {
		window.location.reload();
		}
	},
	data() {
		return {
			board: [],
			clue: null,
			spangram: null,
			strands: [],
			strand_coordinates: [],
			words: [],
			
			attempted_word: null,
			solved_count: 0,
			words_found: 0
		}
	},
	computed: {
		hint_disabled() {
			return this.words_found == 0 || this.words_found % 3 != 0
		}
	},
	created() {
		// Load all JSON files from the directory dynamically
		const context = require.context('@/assets/data/strands', false, /\.json$/);
		const files = context.keys().map(context);

		// Generate a random number between 0 and the number of JSON files minus one
		const randomFileIndex = Math.floor(Math.random() * files.length);

		// Load the selected JSON file
		const selectedFile = files[randomFileIndex];
		this.board = selectedFile.startingBoard;
		this.clue = selectedFile.clue;
		this.spangram = selectedFile.spangram;
		this.strand_coordinates = selectedFile.themeCoords;
		this.strands = Object.keys(this.strand_coordinates);
		this.words = selectedFile.solutions;
	}
}
</script>
