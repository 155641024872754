var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container user-select-none",staticStyle:{"width":"450px !important"}},[_c('b-row',{staticClass:"w-100"},[_c('b-card',{staticClass:"theme w-100",attrs:{"id":"theme-card","no-footer":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("THEME")])]},proxy:true}])},[_c('b-card-text',[_vm._v(_vm._s(_vm.clue))])],1)],1),_c('b-row',{key:_vm.attempted_word,staticClass:"mt-3 w-100 attempted_word"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.attempted_word))])]),_c('b-row',{staticClass:"mt-2 w-100 position-relative"},[_c('letter-grid',{ref:"letter-grid",attrs:{"board":_vm.board,"clue":_vm.clue,"spangram":_vm.spangram,"strands":_vm.strands,"strand_coordinates":_vm.strand_coordinates,"words":_vm.words},on:{"attempted-word":(w) => _vm.attempted_word = w,"word-found":function($event){_vm.words_found++},"solved":function($event){_vm.solved_count++}}})],1),_c('b-row',{staticClass:"mt-3 w-100"},[_c('div',{staticClass:"col-5"},[_c('div',{class:{
					'btn': true,
					'btn-outline-secondary': true,
					'rounded-pill': true,
					'px-3': true,
					'py-2': true,
					'disabled': _vm.hint_disabled,
					'border': true,
					'border-2': true,
					'w-100': true
				},on:{"click":function($event){return _vm.$refs['letter-grid'].mark_hinted()}}},[_vm._v(" Hint")])]),_c('div',{attrs:{"clss":"col-2"}},[_c('b-col',{staticClass:"m-auto counter col w-100 h-100 d-flex justify-content-center align-items-center"},[_c('b',[_vm._v(_vm._s(_vm.solved_count))]),_vm._v(" / "),_c('b',[_vm._v(_vm._s(Object.keys(_vm.strand_coordinates).length))])])],1),_c('div',{staticClass:"col-5"},[_c('div',{class:{
				'btn': true,
				'btn-outline-dark': true,
				'rounded-pill': true,
				'px-3': true,
				'py-2': true,
				'border': true,
				'border-2': true,
				'w-100': true
				},on:{"click":_vm.startNewGame}},[_vm._v(" New Game ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }